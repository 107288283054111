import React from "react";
const TableHead = (props) => { 
  
  const columns = props.headColumns;


  return (
    <thead>
      <tr>
        {
          columns && columns.map((th, i) => {
            return(
           <th key={Math.random()}  className={th.field}>{ th.name }</th> 
            )}
          )
        }
      </tr>
    </thead>
  );
}
export default TableHead;